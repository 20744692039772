<template>
  <div id="results-listing" v-if="items">
    <ResultItem v-for="(item, item_rank) in items" :item_rank="item_rank" :key="item.id" :name="item.name" :id="item.id" :type="item.type" :url="item.external_sites[0].url" :imgUrl="item.img_url" />
  </div>
</template>

<script>
import ResultItem from './ResultItem';

export default {
  props: ['items'],
  components: {
    ResultItem
  }
}
</script>
